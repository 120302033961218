import * as React from "react";
import Header from "../components/Header";
import Seo from "../components/Seo";
import { MDXProps } from "../components/Mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Link from "../components/Link";
import { ArticleProps } from "./ArticleRows";

type MDXNochild = Omit<MDXProps, "children">;

export interface ArticleLayout extends MDXNochild {
  data?: Array<{ node: ArticleProps }>;
  title: string;
  description: string;
  children?: React.ReactNode;
}

const ArticleCards = ({ data, title, description, children, ...props }: ArticleLayout) => {
  console.log(title, "data=", data);

  const articles =
    data?.map(({ node }) => {
      const {
        body,
        id,
        frontmatter,
        frontmatter: { slug },
      } = node;
      const title = frontmatter!.title;
      const image = getImage(frontmatter!.image);
      return { id, title, body, frontmatter, image, slug };
    }) ?? [];

  return (
    <>
      <Seo title={title} description={description} />
      <Header />
      <div className="flex flex-col min-h-screen">
        <main className="grow">
          <Banner
            title={title}
            intro={description}
            actionLink={props.actionLink}
            actionText={props.actionText}
          />

          {children}

          <section className="md:grid md:grid-cols-2 lg:grid-cols-3 gap-8 px-4 mb-20 pt-12 container mx-auto">
            {articles.map((a: ArticleProps) => (
              <Link
                key={a.id}
                to={a.slug!}
                className="md:mb-0 mb-8 no-underline grow shadow-md hover:shadow-xl overflow-clip rounded-md flex flex-col"
              >
                {a.image ? <GatsbyImage image={a.image} alt="" /> : ""}
                <div className="basis-1/2 px-5 py-4">
                  <h2 className="text-xl mb-4">{a.title}</h2>
                  <p className="text-sm mb-2 md:mb-1">{a.frontmatter!.description}</p>
                </div>
              </Link>
            ))}
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ArticleCards;
