import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ArticleCards from "../layouts/ArticleCards";
import { ArticleProps } from "../layouts/ArticleRows";

const SellersPage = () => {
  const data = useStaticQuery<{ allMdx: { edges: Array<{ node: ArticleProps }> } }>(graphql`
    query SellersQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/content/posts/" } }
        sort: { fields: frontmatter___slug, order: DESC }
      ) {
        edges {
          node {
            body
            id
            frontmatter {
              title
              description
              slug
              image {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 2
                    formats: [AUTO, WEBP]
                    placeholder: BLURRED
                    backgroundColor: "transparent"
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <ArticleCards
      title="Seller Support"
      description="If you have questions about selling products on your Music Glue store see our blog section below or send a message"
      data={data.allMdx.edges}
      actionLink="mailto:support@musicglue.com"
      actionText="Get in Contact"
    />
  );
};

export default SellersPage;
